body{margin:0;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif!important;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
label{font-weight:500}
.custom-password{text-security:disc;-webkit-text-security:disc}
.user-ip{font-size:12px;right:15px;margin-top:-11px}
.fa-file-excel-o{cursor:pointer}
.ins-th{width:180px}
.extra-width{width:80px}
.extra-width-a{width:50px;text-align:center}
.fa-trash:before{content:"\f1f8"!important}
.child-headers{margin-bottom:5px}
.video-body iframe{width:100%;height:300px}
.video-body img{width:100%!important}
.add-instance i{color:green!important}
.add-mds-capability{margin-top:10px;margin-left:15px}
.sign-out-btn{margin-top:5px}
.login-user-top{margin-right:10px;margin-top:00px;display:inline-block;font-size:14px}
.user-top-outer{display:flex;justify-content:end;flex-direction:column;text-align:end}
.user-login-box{display:flex;align-items:center;justify-content:end;min-height:35px}
.login-user-top p,.login-user-top span .login-user-top a{line-height:1em}
.dele-select{height:250px!important}
.lookuptable{min-height:350px;max-height:300px}
.frm2{display:none}
.loader2{border:4px solid #ddd;border-radius:50%;border-top:4px solid #3498db;width:30px;height:30px;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite;margin-top:10px;margin-left:70px;display:none}
#fileinput{margin-bottom:5px}
.loader{border:4px solid #ddd;border-radius:50%;border-top:4px solid #3498db;width:30px;height:30px;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite;margin-top:10px;margin-left:70px;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;z-index:1008}
.loader-box{width:100%;background-color:#020202;height:100%;position:fixed;left:0;right:0;z-index:1002;opacity:.5;display:none}
@-webkit-keyframes spin{
0%{-webkit-transform:rotate(0)}
100%{-webkit-transform:rotate(360deg)}
}
@keyframes spin{
0%{transform:rotate(0)}
100%{transform:rotate(360deg)}
}
.show-hide-forms{padding: 15px 0px 5px 0px;}
.navbar-fixed-top{top:66px;border-width:0 0 1px}
.card{border:none!important}
.navbar-fixed-bottom,.navbar-fixed-top{position:fixed;right:0;left:0;z-index:1030}
.tracker-pdf-data{visibility:hidden;height:0;overflow:hidden}
.hide-for-now .answer{display:none}
.hide-arrow{display:none!important}
.custom-navbar-2{position:fixed;right:0;z-index:1030;top:68px;border-width:0 0 1px}
.custom-navbar-4{position:fixed;right:0;z-index:1030;top:66px;border-width:0 0 1px}
.nav .open>a{background-color:transparent}
.nav .open>a:hover{background-color:transparent}
.nav .open>a:focus{background-color:transparent}
#wrapper{padding-left:0;-webkit-transition:all .5s ease;-moz-transition:all .5s ease;-ms-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;padding-top:65px!important}
#wrapper.toggled{padding-left:300px;padding-top:65px!important}
#wrapper.toggled #sidebar-wrapper{width:300px}
#wrapper.toggled #page-content-wrapper{margin-right:-300px}
#sidebar-wrapper{background:#f0f0f0;height:100%;left:220px;margin-left:-220px;overflow-x:hidden;overflow-y:auto;-moz-transition:all .5s ease;-o-transition:all .5s ease;-webkit-transition:all .5s ease;-ms-transition:all .5s ease;transition:all .5s ease;width:0;z-index:1000;border:2px solid #ccc}
.container-2{padding:10px 25px}
#sidebar-wrapper::-webkit-scrollbar{display:none}
#page-content-wrapper{padding-top:4px;width:60%}
#sidebar-wrapper-right{background:#f9f9f9;height:100%;right:220px;margin-right:-220px;overflow-x:hidden;overflow-y:auto;-moz-transition:all .5s ease;-o-transition:all .5s ease;-webkit-transition:all .5s ease;-ms-transition:all .5s ease;transition:all .5s ease;width:0;z-index:1000;border:2px solid #ccc}
#sidebar-wrapper-right::-webkit-scrollbar{display:none}
#wrapper.toggled-2{padding-right:440px}
#wrapper.toggled-2 #sidebar-wrapper-right{width:440px}
#wrapper.toggled-2 #page-content-wrapper{position:absolute}
#wrapper.toggled-2 .fadeInRight{right:454px;-moz-transition:all .5s ease;-o-transition:all .5s ease;-webkit-transition:all .5s ease;-ms-transition:all .5s ease;transition:all .5s ease}
.sidebar-nav{list-style:none;margin:0;padding:0;position:absolute;top:0;width:220px}
.sidebar-nav li{display:inline-block;line-height:20px;position:relative;width:100%}
.fadeInLeft,.fadeInRight{display:inline-block;width:16px;height:16px}
.pat0id{width:100%!important}
.fadeInLeft.is-open,.fadeInRight.is-closed{background:url('/public/layout_arrows.png') no-repeat 0 0!important}
.fadeInLeft.is-closed,.fadeInRight.is-open{background:url('/public/layout_arrows.png') no-repeat 0 -16px!important}
.fab-first-name{width:48%;float:left}
.fab-last-name{width:48%;float:right}
.dropdown-col3{display:none}
.mds-sheets{display:none}
.grid-sacale input{margin-bottom:5px}
.needNicon .need-icon{width:100%!important}
.sidebar-nav li:before{background-color:#6bb4e7}
#redoInfo{margin:10px 0}
.sidebar-nav li a{color:#000;display:block;padding:10px 15px 10px 30px;text-decoration:none}
.panel-btn .btn{line-height:1.2!important;width:200px!important;margin-top:5px}
.sidebar-nav .dropdown-menu{background-color:#222;-ms-border-radius:0;border-radius:0;border:none;-webkit-box-shadow:none;-ms-box-shadow:none;box-shadow:none;margin:0;padding:0;position:relative;width:100%}
.sidebar-nav>.sidebar-brand{font-size:20px;height:65px;line-height:44px}
.fadeInRight{right:20px;-moz-transition:all .5s ease;-o-transition:all .5s ease;-webkit-transition:all .5s ease;-ms-transition:all .5s ease;transition:all .5s ease}
.hamburger{background:0 0;border:none;margin-left:2px;position:fixed;top:68px;z-index:999}
.hamburger:hover{outline:0}
.hamburger:focus{outline:0}
.hamburger:active{outline:0}
.hamburger.is-closed:before{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0);-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;content:'';display:block;font-size:14px;line-height:32px;-ms-opacity:0;opacity:0;text-align:center;width:100px}
.hamburger.is-closed:hover before{-webkit-transform:translate3d(-100px,0,0);-moz-transform:translate3d(-100px,0,0);-ms-transform:translate3d(-100px,0,0);-o-transform:translate3d(-100px,0,0);transform:translate3d(-100px,0,0);-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;display:block;-ms-opacity:1;opacity:1}
.hamburger.is-closed:hover .hamb-top{-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;top:0}
.hamburger.is-closed:hover .hamb-bottom{-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;bottom:0}
.hamburger.is-closed .hamb-top{-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;top:5px}
.hamburger.is-closed .hamb-middle{margin-top:-2px;top:50%}
.hamburger.is-closed .hamb-bottom{-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;bottom:5px}
.hamburger.is-closed .hamb-bottom,.hamburger.is-closed .hamb-middle,.hamburger.is-closed .hamb-top,.hamburger.is-open .hamb-bottom,.hamburger.is-open .hamb-middle,.hamburger.is-open .hamb-top{height:4px;left:0;position:absolute;width:100%}
.hamburger.is-open .hamb-top{-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);-webkit-transition:-webkit-transform .2s cubic-bezier(.73, 1, .28, .08);-moz-transition:-moz-transform .2s cubic-bezier(.73, 1, .28, .08);-ms-transition:-ms-transform .2s cubic-bezier(.73, 1, .28, .08);-o-transition:-o-transform .2s cubic-bezier(.73, 1, .28, .08);transition:transform .2s cubic-bezier(.73, 1, .28, .08);margin-top:-2px;top:50%}
.hamburger.is-open .hamb-middle{display:none}
.hamburger.is-open .hamb-bottom{-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-ms-transform:rotate(-45deg);-o-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-transition:-webkit-transform .2s cubic-bezier(.73, 1, .28, .08);-moz-transition:-moz-transform .2s cubic-bezier(.73, 1, .28, .08);-ms-transition:-ms-transform .2s cubic-bezier(.73, 1, .28, .08);-o-transition:-o-transform .2s cubic-bezier(.73, 1, .28, .08);transition:transform .2s cubic-bezier(.73, 1, .28, .08);margin-top:-2px;top:50%}
.hamburger.is-open:before{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0);-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;content:'';display:block;font-size:14px;line-height:32px;-ms-opacity:0;opacity:0;text-align:center;width:100px}
.hamburger.is-open:hover before{-webkit-transform:translate3d(-100px,0,0);-moz-transform:translate3d(-100px,0,0);-ms-transform:translate3d(-100px,0,0);-o-transform:translate3d(-100px,0,0);transform:translate3d(-100px,0,0);-webkit-transition:all .35s ease-in-out;-moz-transition:all .35s ease-in-out;-ms-transition:all .35s ease-in-out;-o-transition:all .35s ease-in-out;transition:all .35s ease-in-out;display:block;-ms-opacity:1;opacity:1}
.overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:#000;background-color:rgba(0,0,0,.3);z-index:1}
.hamb-bottom,.hamb-middle,.hamb-top{background-color:#000}
.item-fields{margin:10px 0;margin-bottom:80px}
.item-fields #field .card{margin-bottom:10px;border:0}
.item-fields #field .card .card-header{border:0;-webkit-box-shadow:0 0 20px 0 rgba(213,213,213,.5);box-shadow:0 0 20px 0 rgba(213,213,213,.5);border-radius:2px;padding:0}
.item-fields #field .card .card-header .btn-header-link{display:block;text-align:left;color:#222;padding:10px;font-weight:700}
.item-fields #field .card .card-header .btn-header-link:after{content:"\f068";font-family:'Font Awesome 5 Free';font-weight:900;float:right}
.item-fields #field .card .card-header .btn-header-link.collapsed:after{content:"\f067"}
.item-fields #field .card .collapsing{line-height:30px}
.item-fields #field .card .collapse{border:0}
.item-fields #field .card .collapse.show{line-height:30px;color:#222}
.rdw-editor-wrapper{background:#fff}
.btn-update-edit{float:right}
.page-title{text-align:center;margin:0}
.panel-btn{text-align:center}
.nav-content{padding:10px 15px;width:298px}
.nav-content-right{padding:10px 25px;width:430px}
.field-btn{height:25px;display:block;border:none;background:no-repeat;padding:0}
.btn-header-link,.btn.focus,.btn:focus{outline:0;box-shadow:0 0 0 .2rem rgb(0 123 255 / 25%)}
.type-q2{width:94%!important;float:left}
.custom-select{height:162px!important;font-size:14px}
.row-input label{margin-bottom:0!important}
.build-details,.form-control{font-size:14px}
.set-btn{padding:5px 10px}
.set-btn.active{background:#9bd3fa}
.field-div{border:1px solid #ccc;padding:3px 10px;width:488px;margin-bottom:5px;position:relative}
.builder-header-1{margin-left:50px}
.builder-header-2{margin-left:100px}
.builder-header-3{margin-left:150px}
.field-div.question{margin-left:200px}
.page-builder{overflow-y:scroll;height:650px;margin-top:20px;clear:both}
.bottom-btn{margin-top:5px;margin-right:20px}
.top-right-btn{text-align:right;margin-bottom:10px;float:right;height:20px;margin-right: 8px;}
.loaded-file-name{position:fixed;top:44px;font-size:14px;font-weight:500;left:324px;z-index:9}
.required{color:red}
.not-include{display:none!important}
.include-btn{width:185px;float:left;margin-bottom:10px}
.select-delegate-filter{width:282px;float:right;margin-bottom:0;margin-right:20px}
.filter-delegate{width:160px!important;float:right}
.select-attention-filter{width:268px;float:right;margin-bottom:-10px;margin-right:20px}
.select-attention-filter b,.select-delegate-filter b{vertical-align:-webkit-baseline-middle}
.filter-attention{width:140px!important;float:right}
.clear-temp-top{font-size:12px!important;margin-right:2px}
.build-page-title{position:relative}
.build-page-title .page-title{margin-bottom:10px;text-align:left}
.drag-section li{list-style:none!important;cursor:pointer}
.build-page-title .page-title{width:320px;float:left}
.code-dialog{padding:0!important;margin-right:4px}
.showcase{position:relative;top:1px;cursor:pointer;display:inline-block;width:18px;height:11px;opacity:.25;margin-right:15px;cursor:row-resize;background:-webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);background:linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)}
.showcase2{position:relative;top:1px;cursor:pointer;display:inline-block;width:18px;height:11px;opacity:.25;margin-right:15px;cursor:row-resize;background:-webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);background:linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)}
.rdw-image-modal{left:-113px!important;top:-43px!important}
#inlineVideo iframe{width:380px!important;height:275px!important}
.selected{border:2px solid #24b114;box-shadow:0 12px 22px 1px #333}
.field-edit-section .form-group{margin-bottom:10px!important}
code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
.column-input input{display:inline-block;width:220px;margin-bottom:5px}
.row-input input{display:inline-block;width:274px;margin-bottom:5px}
.custom-validation{width:140px!important;display:inline-block!important;margin-left:10px}
.weight-txt{width:240px!important}
.weight-val{width:110px!important}
.small-width{width:75px!important}
.mds-select{float:right;width:170px!important;margin-right:95px}
.build-edit-section .mds-div{height:40px}
.mds-div label{margin-top:8px}
.instance-manager label{margin-bottom:.2rem!important}
.select-mds-filter{margin-top:15px;display:flex;align-items:center;display:block}
.sheet-name-i{width:38%;float:left}
.sheet-row-count{width:10%;display:inline-block}
.sheet-last-update{width:16%;display:inline-block}
.mds-download-icon{float:right}
.download-icon{float:right}
.total-field{margin-right:40px}
.last-field-update{margin-right:150px}
.api-icon img{width:30px;height:28px}
.row-input-date input{background-color:#fff;padding:10px 18px}
.status-tracker-div{font-size:12px;padding:10px;height:648px;overflow:scroll}
.question.not-response{display:none!important}
.download-icon i{margin-left:35px;cursor:pointer}
.field-set-icon i{margin-left:33px!important;cursor:pointer}
.load-mds-button{margin-top:10px}
.load-mds-button2{margin-top:10px}
.btn-undo-data{display:none}
.top-action-icon{display:inline-block;width:100%;text-align:right}
.grid-dropdown label{width:100%}
.grid-dropdown .form-control{width:265px}
.grid-dropdown .same-for-all{width:20px}
.page-builder .input-title{width:235px;display:inline-block}
.page-builder .fs-input-title{width:265px;display:inline-block}
.page-builder .fs-input-title2{width:465px;display:inline-block}
.show-output-txt{text-align:right;margin-top:5px}
.btn-eye-fset{float:right}
.btn-analze-report{display:inline-block}
.btn-analze{margin-left:40px}
.btn-report{margin-left:5px}
.btn-report-pdf{margin-left:5px}
.btn-main-video{margin-left:5px}
.btn-report-pdf i{font-size:36px;color:red}
.api-video iframe{border:none!important}
#kioskUser{z-index:10000!important}
.add-opt-q2{margin-top:5px}
.save-output-send{margin-left:10px;display:inline-block;float:right}
.edit-btns{display:inline;float:right}
.fs-btn{float:right}
.edit-btns1{display:inline-block;float:right}
.code-dialog2{border-color:#ccc;width:130px;height:25px}
.need-icon{width:40px;margin-top:-6px}
.needNote{padding:0 0 0 5px!important}
.error .answer{border:1px solid #f50606}
.answer .error,.build-edit-section .error{border:1px solid #f50606}
.add-delegate{display:none}
.show-output-text{width:200px;float:left;margin-top:5px}
.launch-pad{width:100%}
.v-min{width:108px!important;margin-right:4px}
.v-max{width:108px!important}
.bottom-btn .btn{margin-right:2px;float:right}
.comments{padding:10px 20px;height:525px;overflow:auto;clear:both}
.TestForm{height:550px!important}
.childMain .question{padding-bottom:20px}
.add-new-pos-2{float:right;margin-right:8px;margin-top:8px}
.mui-background{position:relative;outline:0;margin:auto;display:flex;-webkit-box-pack:center;justify-content:center;padding:24px;background-color:#e7ebf0}
.input-text{margin-bottom:15px}
.cus-radio-btn{height:14px;width:14px}
.MuiOutlinedInput-inputMarginDense{padding-top:7.5px!important;padding-bottom:7.5px!important}
.MuiPaper-root .MuiCheckbox-root{padding:5px!important}
.css-1ibwkvh-MuiGrid-root>.MuiGrid-item{padding-top:15px!important}
.completed-forms h6{font-size:18px;margin-bottom:20px}
.completed-forms{margin-top:60px;border:1px solid #ccc;padding:20px 10px}
.launchpad-section .MuiFormControl-root{width:245px}
.MuiSlider-sizeMedium{width:240px!important;height:8px!important}
.mui-slider{width:inherit!important}
.mui-slider span{display:table-cell!important}
.MuiTextField-root{width:100%!important}
.ver-checkbox{margin-left:5px}
.ver-radio{margin-left:15px}
.answer-q5{float:left;margin-top:-1px;margin-right:5px}
.MuiBox-root textarea{border-color:#ccc;outline:inherit!important}
.MuiOutlinedInput-notchedOutline{border-color:#ccc!important}
.sig-horizontal{width:65%}
.sig-save-btn{padding:7px 5px!important;float:left}
.sig-date-field{float:right;width:60%!important}
.file-upload-image{max-width:650px}
.answer iframe{width:100%;height:420px}
.smart-btn{width:14px;height:14px;margin:5px}
.apiPdf-table{max-height:220px;overflow:auto;margin-bottom:10px;background-color:#fff}
.apiPdf-list .fa-file-pdf{color:#00f;font-size:25px;float:right}
.pdf-api-dev embed{height:420px}
.answer .row{margin-bottom:5px}
.cal-res-box{text-align:center;border:1px solid;padding:20px;margin-left:15px}
.boxbegin-div{border:3px solid #ccc;border-bottom:none}
.in-box{border-left:3px solid #ccc;border-right:3px solid #ccc;padding:5px 20px}
.boxend-div,.in-box-last{border:3px solid #ccc;border-top:none}
.auto-tag{color:#00008b;font-weight:700}
.btn-note{padding:5px 10px!important;font-size:1rem!important;float:right}
.txt-additional{font-size:13px;font-style:italic}
.modal-dialog{max-width:590px!important}
.modal-dialog-2{max-width:690px!important}
.grid-option-input{width:100%!important}
.bottom-action-icon{width:50%;margin:0 auto;margin-right:140px;padding:10px 0 25px 0;font-weight:500}
.launch-btn{margin-left:8px!important}
.css-69324s button{margin:8px 0}
.view-launch-pdf a:hover{text-decoration:none}
.launch-pdf span{font-size:18px;vertical-align:super;border:2px solid #cbd2ff;padding:5px;border-radius:2px}
.launch-pdf i{color:#de4040;font-size:38px}
.test-review-table th{font-weight:400;border:none!important;text-align:center}
.test-review-table td{background-color:#fff;text-align:center}
.test-review-table th:first-child{width:200px;text-align:left}
.test-review-table td:first-child{width:200px;text-align:left}
.test-review-table th:nth-child(2){width:150px}
.test-review-table th:nth-child(3){width:110px}
.instance-update{margin-top:12px}
.instance-manager{width:65%;margin:auto;margin-top:80px}
.ins-title{padding:5px;font-size:26px}
.inss-title{font-size:24px}
.associat-select{height:60px!important}
.load-mds-filter{text-align:center;margin-bottom:10px}
.input-check-list{width:50%;float:left}
.ver-checkbox-2{clear:both}
.ogtags-select{height:160px!important}
.launch-table-div{background-color:#fff;min-height:80px;max-height:200px;overflow:scroll;padding:5px}
.launch-table-div table td{padding:5px}
.filter-form{width:150px!important;float:right;margin-top:-5px}
.find-status-filter{width:215px;float:right;margin-bottom:10px;margin-right:0}
.find-facility-filter{width:200px;float:right;margin-bottom:10px;margin-right:10px}
.find-form-filter{width:230px;float:right;margin-bottom:10px;margin-right:10px}
.find-role-filter{width:188px;float:right;margin-bottom:10px;margin-right:10px}
.find-person-filter{width:198px;float:right;margin-bottom:10px;margin-right:10px}
.find-facility-filter b,.find-form-filter b,.find-person-filter b,.find-role-filter b,.find-status-filter b{vertical-align:-webkit-baseline-middle}
.btn-update-status{float:left;margin-top:10px}
#showFormContent{visibility:hidden}
.instance-version{position:absolute;bottom:-10px;font-size:14px}
.tracker-modal{font-size:16px}
.answer .MuiSvgIcon-root{display:block!important}
.fa-flag{color:red;cursor:pointer}
.multis-div{margin-bottom:5px}
.multiss-div{margin-bottom:5px}
.multiss-div select{height:125px!important}
.tracker-table.table td,.tracker-table.table th{padding:3px 10px!important}
.tracker-table button{padding:0 10px!important;height:20px;font-size:14px}
.hide-row{display:none!important}
.k-sort{cursor:pointer}
.status-list{padding:10px;width:50%;margin:auto;margin-top:65px}
.status-table.table td,.status-table.table th{padding:3px 10px!important}
.status-table-div{max-height:400px;overflow-y:scroll;clear:both}
.status-tracker-top{display:inline-block;width:48%;margin-bottom:15px;margin-top:10px;float:right}
.status-tracker-top input{display:inline-block;width:270px;margin-right:15px}
.status-tracker-top button{display:inline-block;margin-left:2px;vertical-align:baseline}
.add-status-tracker-top{width:48%;margin-bottom:5px;margin-top:10px;margin-left:120px}
.add-status-tracker-top input{display:inline-block;width:260px;margin-right:1px}
.add-status-tracker-top button{display:inline-block;margin-left:2px;vertical-align:baseline}
.task-top{display:inline-block;width:48%;margin-bottom:15px;margin-top:10px;float:right}
.task-top input{display:inline-block;width:270px}
.task-top button{display:inline-block;margin-left:2px;vertical-align:baseline}
.add-category-top{width:80%;margin:auto}
.add-category-middel{width:80%;margin:auto}
.status-select-box-2{float:right;width:45%}
.status-select-box-3{float:right;width:45%}
.status-select-box button{margin-left:2px;vertical-align:baseline}
.status-select-box-3 button{margin-left:2px;vertical-align:baseline}
.status-select-box input{width:212px;display:inline}
.status-select-box-3 input{width:212px;display:inline}
.facility-top{display:inline-block;width:260px;margin-bottom:15px;margin-top:10px;float:left;margin-left:120px}
.orignal-status{width:240px!important;display:inline-block!important}
.status-user{margin-right:90px!important}
.hidden-v{visibility:hidden}
.edit-btns-status{display:inline;margin-right:5px;float:right;margin-top:-5px}
.status-table-div input{vertical-align:middle;margin-right:15px}
.status-list h4{text-align:center}
.status-list-workflow h4{text-align:center}
.task-list-workflow h4{text-align:center}
.table-action-icon{text-align:right;clear:both}
.table-action-icon i{margin-right:12px}
.table-action-icon i.fa-envelope{margin-right:12px}
.status-logs{max-height:95px;overflow:auto;margin-bottom:20px;margin-top:10px}
.status-logs ul{list-style:none;padding:0;font-size:13px}
.fab-export-btn{margin-right:25px;margin-top:10px}
.fab-export-btn button{padding:6px 40px;font-size:18px;height:inherit!important}
.status-tracker-tbl{margin-bottom:55px}
.fab-jobid{float:right;margin-right:20px;font-weight:600}
.at-risk{background-color:#ff0}
.show-at-risk-filter{float:right;width:100px;margin-top:5px}
#showAllNotes,#showAtRiskStatus{visibility:hidden}
.task-list-label{font-size:20px;font-weight:500;text-align:center;margin-top:20px}
.stock-form{flex-flow:row!important}
.task-input{width:60%;display:inline-block}
.upload-box .form-control{border:none!important;padding-left:0}
.upload-box button{border-radius:4px!important}
.status-list button{height:35px}
.status-inner{position:relative;width:90%;margin:0 auto}
.status-inner .field-div{margin:2px auto;width:100%}
#taskCt{width:85%;float:left}
.tasks-table-div{max-height:430px;overflow:scroll;clear:both;margin-top:15px}
.tasks-table-div input{vertical-align:middle;margin-right:15px}
.btn-save-status{height:38px!important}
.sataus-header-top{padding:5px 35px}
.status-list-workflow{padding:10px;width:75%;margin:auto;margin-top:65px}
#taskCtWork{width:70%;float:right;margin-right:45px}
.cat-lable{vertical-align:-webkit-baseline-middle;margin-bottom:inherit!important}
.workflow-div{border:2px solid #ccc;padding:15px;margin-top:5px;height:382px}
.task-div{border:1px solid #ccc;padding:3px 10px;width:520px;margin-bottom:5px;position:relative}
.task-input-2{width:70%;display:inline-block}
.task-input-4{width:85%;display:inline-block;height:20px;overflow:hidden}
.workflow-save{margin-top:15px;display:flex}
.save-lable{margin-top:4px;margin-right:5px}
#workflowName{height:38px;width:230px;margin-right:5px}
.workflow-select-list{max-height:350px;overflow-y:scroll}
#wrapper2.toggled-3 #sidebar-wrapper-right{width:440px}
#wrapper2.toggled-3 .fadeInRight{right:454px;-webkit-transition:all .5s ease;transition:all .5s ease}
#wrapperImp.toggled-3 #sidebar-wrapper-right{width:465px}
#wrapperImp.toggled-3 .fadeInRight{right:465px;-webkit-transition:all .5s ease;transition:all .5s ease}
.wdata-table-div{padding:10px}
.workflow-list{margin-top:65px}
.task-list-workflow,.wdata-scheduled{padding:10px;width:65%;margin:auto 155px}
#workflowOption{width:50%;float:left}
.workflow-tasks-div{max-height:430px;overflow-y:scroll;clear:both;margin-top:15px;font-size:14px}
.workflow-tasks-div .task-div{border:1px solid #ccc;padding:3px 10px;width:100%;margin-bottom:5px;position:relative}
.task-edit{float:right;margin-top:5px}
.light-yellow{background-color:#f5f5c5}
.light-green{background-color:#a4f8a4}
.light-blue{background-color:#a1e8f9}
.task-icon{margin-left:3px}
.text-box,.workflow-checkbox{margin-top:10px}
.workflow-item-fields{margin:10px 0;margin-bottom:120px}
.task-input-5{width:26%;display:inline-block;overflow:hidden;height:22px}
.workflow-tasks-div .input-status{font-size:14px;width:10%;display:inline-block}
.workflow-tasks-div .input-role{font-size:12px;width:14%;display:inline-block}
.workflow-tasks-div .input-person{font-size:14px;width:12%;display:inline-block}
.workflow-tasks-div .input-person,.workflow-tasks-div .input-role,.workflow-tasks-div .input-status{border:1px solid #ccc;margin:0 2px;padding:0 5px;background-color:#fff}
.input-option{width:92px;display:inline-block}
.workflow-option{font-size:12px}
.task-note{cursor:pointer}
.task-wip-note{cursor:pointer}
.delete-checkbox{margin-right:5px;vertical-align:middle}
.wip-Note-box{padding:10px;background-color:#fff;max-height:155px;overflow-y:scroll}
.input-wip-note{width:312px!important;height:40px}
.btn-wip-note{margin-left:2px}
.add-wip-note{margin-bottom:10px}
.do-not-share input{display:inline-block;width:auto;vertical-align:bottom;margin-left:120px}
.sharing-code-btn{border:none;font-size:22px;padding:0}
.sharing-code-btn:focus{border:none;outline:0}
.workflow-save{margin-top:15px;display:flex;width:auto;float:left}
.import-btn{margin-top:14px;border:none;font-size:24px;float:right}
.import-btn:focus{border:none;outline:0}
.code-copy{display:flex}
.copy-code-btn{border:none;font-size:20px;background-color:transparent}
.qr-code-btn{border:none;font-size:20px;background-color:transparent}
.copy-code-btn:focus{border:none;outline:0}
.qr-code-btn:focus{border:none;outline:0}
.qr-code-modal{text-align:center}
.qr-code-des{margin-top:20px;font-size:18px}
.qr-code-modal{width:460px!important}
.scanerDiv{width:60%;margin:0 auto}
#html5qr-code-full-region{border:none!important}
.Result-container{text-align:center}
.add-to-list{text-align:center}
@media screen and (max-width:767px){
.scanerDiv{width:90%;margin:0 auto}
.status-list-workflow{width:80%}
}
.builder-filter{width:50%!important}
.select-workflow-div{display:flex}
.workflow-manager{width:80%;margin:0 auto}
.workflow-list h4{text-align:center;margin-top:10px}
.workflow-row .fa-trash{color:red;cursor:pointer}
.workflow-list-table{text-align:center}
.w-name{text-align:left!important}
.workflow-list-table{padding:0 10px!important;height:20px;font-size:14px}
.workflow-list-table.table td,.workflow-list-table.table th{padding:3px 10px!important}
.workflow-list-table button{padding:0 10px!important;height:20px;font-size:14px}
.wfilters{width:60%;margin:0 auto;margin-bottom:20px;position:relative}
.wfilters select{display:inline;margin-right:15px}
.wfilters label{width:80px}
.cat-manage{position:absolute;right:0;top:20px}
.cat-manage button{padding:5px 8px;font-size:14px}
.wc-filter{margin-bottom:5px}
.w-edit-category{display:inline-block!important;width:320px!important;margin-right:5px}
.w-edit-span button{padding:5px}
.new-category-input{width:320px;margin-top:15px}
.w-edit-span .fa-trash{color:red;cursor:pointer}
.preview-workflow-modal{max-width:60%!important}
.pre-w-modal h5,.pre-w-modal h6{text-align:center}
.edit-shareable{margin-top:10px}
.workflow-first-task{width:90%;margin:0 auto;font-size:13px;padding:10px;margin-top:55px}
.wdata-table-div h4{text-align:center;display:inline-block;margin:0 auto}
.add-wip-note2{display:inline-flex}
.workflow-filters{display:inline-flex;margin-bottom:15px}
.wp-filter{width:120px;margin-right:5px}
.startenddate{width:128px;margin-right:5px}
.startenddate label{margin:0}
.startenddate input{width:125px}
.wp-filter-reset button{margin-top:25px}
.wp-filter select{font-size:12px!important;padding:5px}
.view-workflow-details{cursor:pointer}
.back-to-view{float:right;text-decoration:underline;color:#0545cf;cursor:pointer}
.job-header-section{font-size:16px;margin-bottom:10px;padding:5px;background-color:#f2f2f2}
.job-header-section span{margin-right:20px}
.super-view{margin-top:30px;margin-left:10px}
.super-view img{cursor:pointer;transform:rotate(-90deg)}
.workflow-super-table.table td,.workflow-super-table.table th{padding:3px 10px}
.task-header-th{width:42px;max-height:155px;padding:5px 10px!important}
.task-header-span{text-orientation:mixed;writing-mode:vertical-lr;font-size:12px}
.workflow-super-view{width:65%;margin:0 auto;font-size:13px;padding:10px}
.super-table-th{vertical-align:middle!important}
.input-days{display:inline-block;width:60px}
.wname-day-count{text-align:center;margin-top:10px}
#previewWorkflow span{font-size:12px!important}
.new-super-view{width:60px;text-align:center}
.row-red{background-color:#f3baba}
.row-green{background-color:#a3f5a3}
.row-green span{float:right;font-weight:700;color:red}
.row-yellow{background-color:#f7f7b2}
.show-all-note{float:right;margin-right:10px}
.show-all-note .showAllSwitch{margin-right:5px}
.note-row-div{margin:9px 0}
.note-row{display:none;background-color:#ffeac4}
.hide-row{display:none!important}
.scheduled-input .row-input input,.scheduled-input .row-input select{width:340px!important;padding:8px}
.scheduled-input .row-input{max-width:340px}
.repeat-input{width:340px}
.repeat-section{margin-top:20px;margin-bottom:20px}
.repeat-input label{margin-bottom:0!important}
.guild-icon{cursor:pointer}
.guild-icon img{width:22px;margin-left:20px}
.rnote-date{text-align:right;font-size:14px}
.childfield-div,.mdsfield-div{border:1px solid #ccc;padding:3px 10px;width:100%;margin-bottom:5px;position:relative}
.childfield-div .showcase,.mdsfield-div .showcase{float:left;margin-top:5px}
.mdsfield-div .sheet-name-i{width:36%!important}
.mdsfield-div .download-icon i{margin-left:17px}
.childfield-div .download-icon i{margin-left:17px}
.child-sheets .last-field-update{margin-right:125px}
.instance-sec{padding:0!important}
.ins-btn{height:34px}
.setting-ins-section{margin-bottom:20px}
.instance-manager #showOutputText{visibility:hidden}
.showAllSwitch input{visibility:hidden}
.instnace-select{width:258px;float:left}
.instnace-btn i{color:red;font-size:30px;cursor:pointer;margin-top:4px}
.main-ins-sce{margin-bottom:20px}
.custom-mdsFieldCopy{font-size:14px}
.has-copy-sheet .from-mds-view{float:left;width:50%}
.custom-mdsFieldCopy .to-mds-view{float:right;width:50%}
.custom-mdsFieldCopy h3{font-size:17px;margin-top:5px}
.custom-mdsFieldCopy h4{font-size:16px;margin-top:5px}
.custom-mdsFieldCopy h5{font-size:15px;margin-top:5px}
.custom-mdsFieldCopy h6{font-size:14px;margin-top:5px}
.custom-mdsFieldCopy .offset-1{margin-left:15px}
.custom-mdsFieldCopy .col-11{max-width:100%;clear:both}
.to-copty-section .field-div{width:305px}
.to-copty-section .builder-header-1{margin-left:12px}
.to-copty-section .builder-header-2{margin-left:24px}
.to-copty-section .builder-header-3{margin-left:36px}
.to-copty-section .field-div.question{margin-left:60px}
.custom-mdsFieldCopy .from-mds-view{border-right:1px solid #cccc}
.custom-mdsFieldCopy .to-mds-view{border-right:1px solid #cccc}
.from-title{margin-left:5px}
.to-copty-section{padding:0 10px;margin-top:30px}
.to-copy-from{max-width:310px}
.nav-content-right-2{padding:10px}
.btn-copy-to{position:absolute;top:0;left:-30px}
.to-mds-view .copy-h4{margin-left:40px}
.to-copty-section .page-builder{margin-top:0;max-height:465px}
.to-copty-section .input-title{width:172px;display:inline-block}
.from-mds-view .from-title{margin-left:20px}
#wrapper.toggled-2 .toggle-btn-sm{right:310px;transition:all .5s ease}
.create-mds-tab{text-align:center;margin-bottom:20px}
.to-mds-view{position:relative}
.save-new-mds-guid{margin-top:20px}
.save-inst{float:left}
.new-destination{float:right;margin-top:15px}
.add-ins-destination{margin-top:30px;margin-bottom:25px}
.check-field-copy-2{float:right}
.mdsFieldCopy .header-m{float:left}
.check-field-copy{text-align:right}
.check-field-copied img{margin-left:3px}
.show-copy-view{float:right;margin-top:22px}
.parent-header h3,.parent-header h4,.parent-header h5,.parent-header h6{clear:both;cursor:pointer}
.filed-copied{color:red}
.copy-field-number{border:1px solid #ccc;padding:5px;margin-top:10px;background-color:#fff}
.top-header{position:fixed;top:0;z-index:8;width:100%;background-color:#fff!important;left:0;border-bottom:1px solid rgba(0,0,0,.125)}
.mdsFieldCopy{max-height:500px}
.mds-manager{margin-top:65px}
.temp-left-side{float:left;width:88%!important}
.plus-right-side{float:right;margin-top:-10px}
.mds-sheet-sec{margin:20px 0 20px 0;background-color:#efefef}
.mds-sheet-sec .align-items-end{padding:10px}
.mds-download-icon .mds-action-btn{text-align:center;width:44.5px;cursor:pointer;display:inline-block}
.mds-download-icon .frm2{display:none}
.top-action-span{font-size:14px;float:left;width:64%}
.top-action-span2{font-size:12px;float:right;width:35%;margin-right:5px}
.top-action-span span{padding:0 60px 0 0}
.top-action-span2 span{padding:0 4.5px}
.mongo-lable{padding:0 6px 0 10px!important}
.mongo-lable2{padding:0 28px 0 10px!important}
.has-in-mongo{color:green}
.not-in-mongo{color:red}
.top-version{padding-left:12px}
.all-mongo{float:right;margin-top:-50px}
.sheet-name-child-i{width:36%;float:left}
.select-lan{display:block;width:80px;font-size:1rem;font-weight:400;line-height:1.5;color:#495057;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;border-radius:4px}
.select-lan:focus-visible{outline:-webkit-focus-ring-color auto 0}
.mds-ssheets{display:none}
.select-mds-filter label{width:165px;margin-bottom:2px;display:block;font-size:12px}
.mds-ssheets select{width:220px!important;display:inline-block}
.filter-mds{width:190px!important}
.mds-manager-mongo{margin-top:68px}
.mg-sheet-row-count{width:10%;display:inline-block;text-align:center}
.mg-sheet-last-update{width:16%;display:inline-block;text-align:center}
.mg-mds-download-icon{float:right;display:inline-flex;width:505px}
.mg-mds-download-icon2{float:right;display:inline-flex;width:350px}
.mg-mongo-mode{width:188px}
.mg-mongo-mode select{height:30px!important;padding:0 10px}
.mg-mongo-mode2{width:160px}
.mg-mongo-mode2 select{height:30px!important;padding:0 10px}
.mg-mds-download-icon .mds-action-btn{text-align:center;width:44.5px;cursor:pointer;display:inline-block}
.mg-top-action-icon{display:inline-block;width:100%;margin-top:50px;font-size:12px}
.mg-top-action-icon2{display:inline-block;width:100%;font-size:14px}
.mg-top-action-span{float:left;width:46%;text-align:right;margin-top:18px;font-size:14px}
.mg-top-action-span2{float:right;width:500px}
.mg-top-action-span3{float:right;width:350px;margin-top:18px}
.mg-sheet-name-i{width:34%;float:left}
.mg-mongo-lable{padding:0 5px!important;text-align:center;display:inline-block;width:68px}
.mg-mongo-lable2{padding:0 5px!important;text-align:center;display:inline-block;width:33%}
.mg-mongo-mode-lable{width:88px;padding:0 5px!important;text-align:center;display:inline-block}
.mg-list-group-item{position:relative;display:block;padding:4px 15px;background-color:#fff;border:1px solid rgba(0,0,0,.125);margin-bottom:5px;padding-right:0}
.mg-mongo-val{width:20%;text-align:center}
.mg-mongo-val2{width:50%;text-align:center}
.mg-page-title{float:left}
.mongo-header{padding:8px 10px;clear:both;width:100%;float:left;position:fixed;width:80%;left:0;right:00;margin:0 auto;z-index:9;background-color:#fff;top:69px}
.mongo-header2{padding:8px 10px;clear:both;width:100%;float:left;left:0;right:00;margin:0 auto;z-index:9;background-color:#fff;top:67px}
.custom-container{width:80%;margin:auto;margin-top:80px}
.mg-mds-page-title h4{font-size:20px}
.mg-upload-all{float:right;margin-top:10px}
.mg-mongo-name{float:left;margin-left:18px}
.mg-page-title h4{position:absolute;top:15px;font-size:20px}
.pointer{cursor:pointer}
.layout-tbl{width:100%;font-size:14px}
.layout-tbl td,.layout-tbl th{padding:2px}
.child-sheets,.mds-sheets,.mg-child-sheets{background-color:#efefef;padding:2px 10px;width:100%;float:left;margin-top:60px}
.childfield-div,.mdsfield-div{background-color:#fff}
.bottom-action-icon{display:none}
.mds-manager .col-md-2{padding-right:0}
.tra-child-sheets{background-color:#efefef;padding:2px 10px;width:100%;float:left}
.tra-sheet-row-count{width:10%;display:inline-block;text-align:center}
.tra-sheet-last-update{width:16%;display:inline-block;text-align:center}
.tra-mds-download-icon{float:right;display:inline-flex;width:550px}
.tra-mds-download-icon2{float:right;display:inline-flex;width:200px}
.sheet-method{margin-left:5px}
.tra-mds-download-icon .mds-action-btn{text-align:center;width:44.5px;cursor:pointer;display:inline-block}
.tra-top-action-icon{display:inline-block;width:100%;margin-top:50px;font-size:12px}
.tra-top-action-icon2{display:inline-block;width:100%;font-size:12px}
.tra-top-action-span{float:left;width:42%;text-align:right;margin-top:18px;font-size:14px}
.tra-top-action-span2{float:right;width:550px}
.tra-top-action-span3{float:right;width:200px}
.tra-sheet-name-i{width:30%;float:left}
.tra-mongo-lable{padding:0 4px!important;text-align:center;display:inline-block;width:65px}
.tra-mongo-lable2{padding:0 4px!important;text-align:center;display:inline-block;width:33%}
.tra-list-group-item{position:relative;display:block;padding:4px 15px;background-color:#fff;border:1px solid rgba(0,0,0,.125);margin-bottom:5px;padding-right:0}
.tra-mongo-val{width:20%;text-align:center}
.tra-mongo-val2{width:33%;text-align:center}
.tra-page-title{float:left}
.tra-mds-page-title h4{font-size:20px}
.tra-upload-all{float:right}
.tra-mongo-name{float:left;margin-left:18px}
.fa-language{font-size:22px!important}
.fa-language{cursor:pointer}
.excel-download{margin-left:20px;cursor:pointer}
.language-popup{max-width:850px!important}
.layout-tbl-lan{width:100%;font-size:12px}
.layout-tbl-lan td,.layout-tbl-lan th{padding:2px 5px}
.sheet-language{display:inline-block}
.frm1 select{width:100px}
.has-language{color:#000;border:1px solid #000}
.preview-mode{width:128px;float:left;margin-right:10px;cursor:pointer;margin-top:3px}
.preview-mode-2{width:20px;float:left;margin-right:10px;cursor:pointer;margin-top:3px}
.unselect-all{float:left;margin-right:10px;margin-top:3px}
.unselect-all .un-all{cursor:pointer;text-decoration:underline;padding:0 4px}
.preview-mode-off{width:155px;margin-right:140px;cursor:pointer;margin-top:5px;float:right}
.tb1{width:80px}
.tb2{width:80px}
.tb3{width:65px}
.tb4{width:78px}
.tb5{width:68px}
.tb6{width:55px}
.tb7{width:40px}
.tb8{width:80px}
.select-method{width:88px}
.status-hide{color:#ccc}
.status-error{color:red}
.status-pending{color:#ff0}
.status-success{color:green}
.wp-mode-filter{width:250px;float:right;display:inline-grid}
.wp-search-filter{width:280px;float:right;display:inline-grid;margin:8px}
.search-icon{float:right;margin-top:-28px;margin-right:10px;font-size:18px!important;color:#c5c5c5;text-align:right}
.MuiTable-root .MuiPaper-root{box-shadow:inherit}
.action-btn{font-size:10px;margin-bottom:2px}
.action-btn span{padding:0 3px;background-color:#f1efef;margin-right:3px;border-radius:1px}
.field-text-btn{margin-right:220px!important;margin-left:50px}
.user-manager-mongo{margin-top:68px}
.pass-error{color:red}
.mg-add-user{float:right}
.mg-user-title{float:left}
.mongo-instance{margin-top:20px}
.mds-file-list{margin-top:10px}
.assign-btn{padding:0!important;color:#0000cf!important}
.track-list{margin-top:20px;padding:0!important}
.track-list-ul{padding:0!important;list-style:none;width:300px;max-height:145px}
.track-list li{border-bottom:1px solid #ccc;padding:5px 0;width:100%}
.track-list li:last-child{border-bottom:none}
.list-li-name{display:flex;justify-content:space-between}
.track-delete{float:right}
.add-track-n{margin-top:2px;width:220px!important;float:left;margin-right:2px}
.user-rights label{width:100%}
.merge-code-btn{padding:0!important;margin-left:5px!important}
.lock-unlock{width:10px;float:left;margin-right:10px;font-size:20px;cursor:pointer}
.tst-page-title{float:left}
.tst-user-select{float:right;width:220px}
.test-header .form-group{position:relative}
.test-header .form-group label{color:#787878;position:absolute;margin:0;font-size:13px;top:-10px;left:15px;background-color:#fff;padding:1px 5px}
.test-header .form-group select{margin-top:10px}
.child-sheets,.mds-sheets,.tst-child-sheets{background-color:#efefef;padding:2px 10px;width:100%;float:left}
.tst-top-action-icon{display:inline-block;width:100%;margin-top:0;font-size:14px}
.tst-top-action-span{float:left;width:38%;text-align:right;font-size:14px}
.tst-top-action-span2{float:right;width:620px}
.tst-mongo-name{float:left;margin-left:18px}
.tst-mongo-lable{padding:0 5px!important;text-align:center;display:inline-block;width:88px}
.tst-sheet-name-i{width:25%;float:left}
.tst-sheet-last-update{width:16%;display:inline-block;text-align:center}
.tst-mds-download-icon{float:right;display:inline-flex;width:620px}
.tst-mongo-val{width:15%;text-align:center}
.tst-mongo-val2{width:84px;text-align:center}
.rating-start{font-size:14px}
.rating-start .rated{color:#f1cc0b}
.layout-filter{margin-bottom:5px}
.form-rating input{width:65px}
.selected-patient{margin-left:20px}
.col-filter{max-width:20%!important}
.tst-mongo-lable span{display:inline-block}
.tst-mongo-lable .pointer{display:inline-block;vertical-align:middle;margin-left:5px}
.custom-switch-required{width:140px;float:right;margin-top:6px;margin-right:10px}
.suggestion-box-section{display:none;padding:10px}
.suggestion-box p{margin:0}
.suggestion-box{margin-bottom:10px}
.mobile-sign-out{display:none}
.mobile-menu{display:none}
.btn-padding{padding:0!important}
.btn-header-link:after{content:"\f107";font-family:'Font Awesome 5 Free';font-weight:900;float:right;font-size:14px;margin-right:-6px}
.toggle-btn{display:none}
.site-section{padding:7rem 0}
aside,main{height:100vh;min-height:580px}
aside{width:300px;left:0;z-index:1001;position:fixed;-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);background-color:#fff;-webkit-transition:1s -webkit-transform cubic-bezier(.23, 1, .32, 1);transition:1s -webkit-transform cubic-bezier(.23, 1, .32, 1);-o-transition:1s transform cubic-bezier(.23, 1, .32, 1);transition:1s transform cubic-bezier(.23, 1, .32, 1);transition:1s transform cubic-bezier(.23, 1, .32, 1),1s -webkit-transform cubic-bezier(.23, 1, .32, 1)}
.toggled aside{-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);background:#f0f0f0}
aside .toggle{padding-left:5px;padding-top:5px;position:absolute;right:0;-webkit-transform:translateX(100%);-ms-transform:translateX(100%);transform:translateX(100%);z-index:999999}
.toggled aside .toggle .burger span,.toggled aside .toggle .burger:after,.toggled aside .toggle .burger:before{background:#000}
aside .side-inner{height:100vh;overflow-y:scroll;-webkit-overflow-scrolling:touch;background-size:cover;background-position:center center;background-repeat:no-repeat}
aside .side-inner .sidebar-content .bio{margin-bottom:40px}
aside .side-inner .sidebar-content h3{font-size:3rem;color:#000}
aside .side-inner .sidebar-content p{color:#000;line-height:1.8}
aside .side-inner .sidebar-content .main-nav{margin-bottom:40px}
aside .side-inner .sidebar-content .main-nav li a{color:#000}
aside .side-inner .sidebar-content .social li{display:inline-block}
aside .side-inner .sidebar-content .social li a{width:50px;height:50px;position:relative;background:rgba(255,255,255,.5);display:block;border-radius:50%}
aside .side-inner .sidebar-content .social li a span{position:absolute;color:#000;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}
aside .side-inner .sidebar-content .social li a:hover{background:#fff}
.burger{width:20px;height:24px;cursor:pointer;position:relative;z-index:99;float:right}
.burger span,.burger:after,.burger:before{width:100%;height:2px;display:block;background:#000;border-radius:2px;position:absolute;opacity:1}
.burger:after,.burger:before{-webkit-transition:top .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1);transition:top .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1);-o-transition:top .35s cubic-bezier(.23, 1, .32, 1),transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1);transition:top .35s cubic-bezier(.23, 1, .32, 1),transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1);transition:top .35s cubic-bezier(.23, 1, .32, 1),transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1);-webkit-transition:top .35s cubic-bezier(.23, 1, .32, 1),-webkit-transform .35s cubic-bezier(.23, 1, .32, 1),opacity .35s cubic-bezier(.23, 1, .32, 1),background-color 1.15s cubic-bezier(.86, 0, .07, 1);content:""}
.burger span{top:15px}
.burger:before{top:7px}
.burger:after{top:23px}
.burger.active span{opacity:0}
.burger.active:after,.burger.active:before{top:40%}
.burger.active:before{-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg)}
.burger.active:after{-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg)}
.burger:focus{outline:0}
.close-screen{display:none}
.full-close-screen{display:none}
.custom-TestForm{width:80%!important}
.custom-Child,.custom-MDS{position:absolute}
.not-r{display:none}
.review-img{margin-left:5px;width:20px}
.content-improve{margin-top:70px}
.build-details p{margin:0}
.visible-txt-imp{font-size:18px;margin-top:10px;margin-bottom:10px;background-color:#fff}
.review-btn-1{float:right}
.review-btn-1 .btn-link{padding:5px}
.review-txt-1{margin-bottom:15px;background-color:#fff;padding:5px}
.review-txt-1 p{margin-bottom:10px}
.review-txt-2{margin-bottom:10px;background-color:#fff;padding:5px}
.imp-panel-btn{position:fixed;width:460px;text-align:center;bottom:0;padding:10px 10px;background-color:#fff}
.nav-right-improve-mode{padding-bottom:60px}
.nav-right-improve-mode .btn-header-link.collapsed:after{content:"\f107"}
.nav-right-improve-mode .btn-header-link:after{content:"\f106";font-family:'Font Awesome 5 Free';font-weight:900;float:right}
.nav-right-improve-mode .card .btn-header-link{background-color:transparent;border:none;font-size:18px;font-weight:500;width:100%;text-align:left;box-shadow:none;padding:10px 10px 10px 0}
.nav-right-improve-mode .card .btn-header-link:focus{outline:0}
.nav-right-improve-mode .card{background-color:#f9f9f9}
.accordion{margin-bottom:15px}
.last-suggestion-box{border:1px solid #ccc;padding:5px;box-shadow:0 2px 20px 2px #ccc;font-size:14px}
.tt-number{text-align:center}
.tag-section{margin:5px 0}
.btn-insert-video{padding:2px 5px}
.v-tag{padding:5px;background-color:#efefef;display:inline-block;margin-right:10px}
.v-list{font-size:14px}
.video-section h6{margin-bottom:10px}
.video-section-table{max-height:265px;overflow-y:scroll}
.video-section table td{padding:5px 10px}
.btn-embed{padding:4px 8px!important}
.video-preview{background:rgb(0 0 0 / 42%)}
.modal-video-pre{max-width:518px!important}
.video-list-div{min-height:410px}
input[type=checkbox].css-checkbox{position:absolute;overflow:hidden;clip:rect(0 0 0 0);height:1px;width:1px;margin:-1px;padding:0;border:0}
input[type=checkbox].css-checkbox+label.css-label{padding-left:20px;height:15px;display:inline-block;line-height:15px;background-repeat:no-repeat;background-position:0 0;font-size:15px;vertical-align:middle;cursor:pointer}
input[type=checkbox].css-checkbox:checked+label.css-label{background-position:0 -15px}
.lite-green-check{background-image:url(/public/lite-green-check.png)}
.lite-red-check{background-image:url(/public/lite-red-check.png)}
.lite-plus{background-image:url(/public/lite-plus.png)}
.lite-blue-check{background-image:url(/public/lite-blue-check.png)}
.lite-kiosk-check{background-image:url(/public/kiosk.png)}
.lite-gray-check{background-image:url(/public/lite-gray-check.png)}
.check-list-cm label{margin-bottom:0}
.check-list-cm .fa-users{font-size:14px}
.lite-gray-check{cursor:inherit!important}
.row-input-check{margin:10px 0}
.test-bottom-button{display:flex;justify-content:end;gap:15px;padding-right:20px;padding-bottom:10px}
.custom-close-btn{text-align:right;position:absolute;right:8px;top:8px;z-index:2}
.start-w-modal{margin-top:15px}
.option-keyvalue{display:inline-flex}
.option-keyvalue-v{display:inline-flex;width:75%}
.keyvalue{padding:2px}
.keyvalue-v{margin-top:28px;padding:2px}
.option-value{margin-top:10px}
.txt-key-val p{width:144px;padding:2px 10px}
.txt-key-val,.txt-key-val div{margin:0!important;padding:0!important;background:#f0f0f0}
.txt-key-val{margin-bottom:2px!important;padding:0 5px!important}
.txt-key-val button{float:right}
.password-input{width:95%!important}
#showPassword{float:right;margin-top:-28px;margin-right:2px}
#showPasswordEdit{float:right;margin-top:-28px}
.option-div{border:1px solid #ccc;padding:3px 10px;margin-bottom:5px;position:relative}
.op-title{width:180px;display:inline-block}
.op-title2{width:272px;display:inline-block}
.op-val{width:95px;display:inline-block}
.op-delete{width:15px;display:inline-block}
.option-div{z-index:9999;background:#fff}
.hidden-field{display:none!important}
.option-key-name-bx{height:300px!important}
.option-key-name{margin-bottom:10px}
.dimension-table{background:#fff}
.check-list-popup{display:flex;margin-right:30px}
.check-list-popup div{padding:4px}
.action-btn-2{font-size:11px;margin-bottom:2px;position:absolute;margin-top:-40px;right:85px}
.action-btn-2 span{padding:0 3px;background-color:#f1efef;margin-right:3px;border-radius:1px}
.btn-upload-video i{font-size:28px}
.msg-success{font-size:18px;color:green}
.wp-search-mds{margin-top:10px;background-color:#fff}
.hightlight{background:#6af7d0;padding:0 5px}
.mds-go-to-link{margin-top:20px}
.mds-filter-form{text-align:left;width:298px}
.field-btn-new{margin-bottom:5px;background:#fff;width:268px}
@media only screen and (max-width:1024px){
.toggle-btn{display:block}
.loaded-file-name{top:65px;left:150px;font-size:12px;z-index:1}
.custom-TestForm .TestForm{height:calc(100vh - 260px)!important}
#wrapper{padding-top:70px!important}
#page-content-wrapper{width:94%!important}
.show-output-txt{margin-top:10px}
.full-close-screen{display:block;margin-top:-15px}
.sidebar{z-index:999999}
aside .toggle{z-index:999999;position:fixed}
.content-improve .col-md-8{max-width:100%}
.content-improve .preview-mode-off{margin-right:0!important;display:inline-block!important}
.builder-header-1{margin-left:20px}
.builder-header-2{margin-left:40px}
.builder-header-3{margin-left:60px}
.field-div.question{margin-left:80px}
#wrapperImp.toggled-3 #sidebar-wrapper-right{width:400px}
#wrapperImp.toggled-3 .fadeInRight{right:400px}
.nav-content-right{width:400px}
}
@media (min-width:991px) and (max-width:1024px){
.preview-mode-off{display:none}
#wrapper.toggled{padding-left:0}
.hamburger{z-index:999999}
}
@media (min-width:768px) and (max-width:991px){
.preview-mode-off{display:none}
#wrapper.toggled{padding-left:0}
.hamburger{z-index:999999}
}
@media only screen and (max-width:767px){
.login-user-top{display:none}
.top-header .row .col-3,.top-header .row .col-6{padding-left:5px;padding-right:5px}
.top-header h4{font-size:14px;margin-bottom:2px}
.top-header .instance-version{position:initial;font-size:12px}
.user-top-outer{display:inline-block}
.loaded-file-name{top:68px;left:48px;font-size:12px;z-index:1}
.custom-navbar{width:60%!important;left:0!important;right:0!important;margin-left:-230px!important}
#wrapper.toggled #sidebar-wrapper{width:60%!important;left:0!important;margin-left:0!important}
#wrapper.toggled #page-content-wrapper{margin-right:0;position:inherit}
#wrapper.toggled{padding-left:0}
.hamburger{z-index:999999}
#page-content-wrapper{width:100%}
.nav-content{width:100%}
.preview-mode-off{display:none}
.select-delegate-filter{width:50%;position:relative;margin-right:0}
.select-delegate-filter b{font-size:12px;position:absolute;top:-8px;background-color:#fff;padding:0 2px;left:12px}
.filter-delegate{width:100%!important}
.from-mds-view{margin-top:25px}
.comments{padding:10px 2px}
.TestForm h3{font-size:20px}
.TestForm .col-11{max-width:98%!important}
.TestForm h4{font-size:16px}
.TestForm h5{font-size:14px}
.fadeInLeft{margin-left:-10px!important}
.offset-1{margin-left:0!important}
.instance-manager{width:100%;margin:auto;margin-top:40px;font-size:14px}
.instance-manager{margin-top:50px}
.inss-title{margin-bottom:10px}
.mongo-header2{padding:10px 0}
.col-filter{max-width:inherit!important}
.mds-manager-mongo{padding-left:0!important;padding-right:0!important}
.child-sheets,.mds-sheets,.mg-child-sheets{overflow:scroll}
.scroll-box{width:1100px}
.test-header h4{margin-bottom:15px!important}
.mongo-header{width:100%;position:initial;padding:0;margin-bottom:15px}
.mg-page-title h4{position:initial;margin-bottom:15px}
.mongo-header .mg-page-title{width:100%}
.mongo-header .mg-upload-all{float:left}
.mg-top-action-icon{margin-top:0}
.child-sheets,.mds-sheets,.tst-child-sheets{overflow:scroll}
.builder-header-1{margin-left:5px}
.builder-header-2{margin-left:10px}
.builder-header-3{margin-left:15px}
.field-div.question{margin-left:20px}
#wrapperImp.toggled-3 #sidebar-wrapper-right{width:400px}
#wrapperImp.toggled-3 .fadeInRight{right:400px}
}